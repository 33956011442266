import ScrollToTopOnMount from "components/General/ScrollToTopOnMount";
import Header from "components/General/Header";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useForm } from "react-cool-form";
import { useState } from "react";
import axios from "axios";
import Botpoison from "@botpoison/browser";

const Field = ({ label, id, error, ...rest }) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <input id={id} {...rest} />
    {error && <div>{error}</div>}
  </div>
);

const Textarea = ({ label, id, ...rest }) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <textarea id={id} {...rest} />
  </div>
);

const Terms = (props) => {
  const [isSubmitted, setSubmitted] = useState(false);
  const isMdScreen = useMediaQuery({ query: "(max-width: 992px)" });
  const isSmScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const { form, use } = useForm({
    // (Strongly advise) Provide the default values
    defaultValues: { name: "", email: "", password: "" },
    // The event only triggered when the form is valid
    onSubmit: (values) => {
      handleFormSubmit(values);
      setSubmitted(true);
    },
  });

  const handleFormSubmit = async (values) => {
    const botpoison = new Botpoison({
      publicKey: "pk_1e0b08b6-af15-49c2-b8f3-df0eb450d149",
    });

    try {
      const apiUrl = "https://submit-form.com/c6X5EwLHB"; // Replace with your API endpoint
      const { solution } = await botpoison.challenge();
      const response = await axios.post(apiUrl, {
        name: values.name,
        email: values.email,
        message: values.message,
        _botpoison: solution,
      });

      // Return the API response data
      return response.data;
    } catch (error) {
      // Handle API request errors
      console.log(error);
      // throw error;
    }
  };

  const errors = use("errors", { errorWithTouched: true }); // Default is "false"

  return (
    <>
      <ScrollToTopOnMount />
      <Header isSmScreen={isSmScreen} />
      <main>
        <section id="impressum-content">
          <Container>
            <div className="impressum">
              <div>
                <p>1. ACCEPTANCE OF TERMS</p>
                <div>
                  “Champignons of Arborethia” (hereinafter “Champz”) is an interactive browser-game, developed and provided by RiddleDrops
                  Gmbh (hereinafter “RiddleDrops”). The game can be accessed via https://play.champz.world. By accessing or using Champz,
                  you acknowledge that you have read, understood, and agree to be bound by these Terms of Use, as well as any additional
                  guidelines, rules, or terms posted within the game. These terms may be updated or modified by RiddleDrops at any time
                  without prior notice, and your continued use of the game constitutes your acceptance of any such changes.
                </div>{" "}
                <br />
                <br />
                <p>2. THE GAME</p>
                <div>
                  2.1. Game Guide and comprehensive Explanation Champz provides a comprehensive game guide and detailed explanation of its
                  mechanics, features, and gameplay. This guide is available at https://docs.champz.world (hereinafter “Game Guide”). By
                  accessing and using Champz, you acknowledge and agree to review the Game Guide and abide by its instructions, rules, and
                  recommendations. The Game Guide is considered an integral part of these Terms of Use and should be consulted for a
                  comprehensive understanding of Champz
                </div>
                <br />
                <div>
                  2.2. Modifications to the Game Guide Champz reserves the right to update, modify, or enhance the Game Guide at any time to
                  reflect changes in the game's mechanics, features, or any other relevant aspect. You are responsible for regularly
                  reviewing the updated version of the Game Guide to ensure compliance with the latest guidelines and instructions. In case
                  of any discrepancies or conflicts between these Terms of Use and the Game Guide, these Terms of Use shall prevail.{" "}
                </div>
                <br />
                <div>
                  2.3. Updates and Changes to the Game Champz reserves the right to update, modify, or discontinue any aspect of the game,
                  including its features, gameplay, rewards, or these Terms of Use, at any time and without prior notice. You agree that
                  Champz shall not be liable to you or any third party for any modifications, interruptions, or discontinuations of the
                  game.{" "}
                </div>
                <br />
                <div>
                  2.4. Dynamic Character Attributes Champz introduces a dynamic character attribute system, where in-game characters and
                  their associated meta data on-chain may change based on specific in-game mechanics, events, or player choices. Character
                  attributes, skills or abilities are subject to modification, improvement, or degradation throughout the course of the
                  game. This dynamic nature adds an element of unpredictability and strategic depth to the gaming experience. In-game
                  mechanics, events or player decisions may influence the following aspects of character attributes: • Skills and Abilities:
                  Characters may gain new skills, improve existing abilities, or acquire temporary boosts through in-game activities. •
                  Attributes: Physical and mental attributes of characters, such as “Attack damage”, “Defense” or “Mana”, may be influenced
                  by specific in-game events or decisions.
                </div>
                <div>
                  {" "}
                  2.4.1. Transparency and Communication RiddleDrops is committed to providing transparency regarding the factors that
                  influence changes in character attributes. The Game Guide will provide additional details on how various in-game mechanics
                  can impact character attributes, ensuring that players have the information needed to make informed decisions.
                </div>
                <div>
                  2.4.2. Player Agency The dynamic nature of character attributes is designed to enhance player agency and create a living,
                  evolving game world. Player decisions, achievements, and interactions directly contribute to the development of their
                  characters. Champz recognizes the importance of player agency and strives to provide a diverse range of choices, each with
                  potential repercussions on character attributes.{" "}
                </div>
                <div>
                  2.4.3. Balancing and Fairness Champz is committed to maintaining a balanced and fair gaming experience. Changes to
                  character attributes are designed to enrich the narrative and gameplay, ensuring that both positive and negative outcomes
                  contribute to an engaging and challenging experience for players. Champz may periodically adjust in-game mechanics to
                  maintain balance and address player feedback, ensuring a dynamic yet fair environment.
                </div>
                <div>
                  {" "}
                  2.4.4. Feedback Mechanism Champz welcomes player feedback regarding the dynamic character attribute system. Players are
                  encouraged to provide insights, suggestions, and concerns through designated communication channels, contributing to the
                  ongoing improvement and refinement of the gaming experience. By engaging with Champz, you acknowledge and accept the
                  dynamic nature of character attributes as an integral part of the gaming experience. Adaptability and strategic thinking
                  are encouraged as you navigate the ever-changing landscape of Arborethia.{" "}
                </div>
                <br />
                <br />
                <p>3. $CHAMPZ TOKEN</p>
                Champz utilizes a native cryptocurrency token, referred to as $CHAMPZ as a form of gated access to the game as well as
                certain features, benefits, or content within the game. The total number of $CHAMPZ tokens needed to play the game or access
                various features will always be stated in the Game Guide. RiddleDrops reserves the right to change these numbers anytime in
                the future. By acquiring, holding, or utilizing $CHAMPZ, you acknowledge and agree to comply with any additional terms and
                conditions related to its use, including but not limited to token sale agreements, wallets, and external platforms.
                <br />
                <br />
                <p>4. EARN MECHANICS</p>
                Champz incorporates an in-game earn mechanics. Players can earn rewards in the form of in-game assets, an in-game currency
                called “Truffles”. Truffles can be exchanged to USDC in-game anytime at a fixed rate of 10,000 (in words: ten thousand) truffles per 1 (in
                words: one) USDC. The minimum amount of Truffles to swap is 10,000. Players can claim this USDC to their wallets, paying gas
                fees of the Immutable ZkEvm network. RiddleDrops reserves the right to modify, substitute, or cancel the earning mechanics without
                prior notice. 
                <br />
                <br />
                <p>5. CHAMPZ NFTS</p>
                With the game update of 15th December 2023, RiddleDrops enables the free creation (minting) of 9000 previously rolled
                characters as Non-Fungible Tokens (NFTs) on the Ethereum Blockchain. Thereby, players obtain unique digital assets that can
                be traded on supported NFT marketplaces that enable the interoperability of the NFTs. While minting is free, players should
                be aware that external factors, such as blockchain transaction fees (gas fees), may apply. Gas fees are determined by the
                respective blockchain network and are required to process and confirm transactions on the blockchain. The responsibility for
                covering gas fees associated with minting rests with the player initiating the minting process. The trading of NFTs can take
                place on NFT marketplaces that support the relevant blockchain standards. Players can buy, sell, or trade their NFTs
                according to the guidelines of the respective marketplace. RiddleDrops promotes responsible trading of NFTs and encourages
                players to familiarize themselves with the terms and policies of the relevant blockchain networks and marketplaces.
                <br />
                <div>
                  5.1. Centralized Storage of Metadata Champz maintains a centralized storage system for the metadata associated with NFTs,
                  containing information such as character attributes, ownership history, and other relevant details. This centralized
                  storage ensures the accessibility and consistency of metadata across the Champz platform and affiliated NFT marketplaces.
                  Players should be aware that the metadata stored centrally may be subject to changes or updates in the future as described
                  in chapter 2. RiddleDrops is committed to protecting the rights of artists and game developers by taking action against
                  activities that impede or suppress fair compensation (royalties) for the created NFTs. This may include measures to ensure
                  that NFTs are traded on authorized and licensed platforms, as well as the exclusion of marketplaces that violate the
                  principles of fair compensation. RiddleDrops reserves the right to update or modify the mechanisms for minting and trading
                  NFTs, as well as the associated conditions. Such changes will be communicated to players through our official
                  communication channels.
                </div>
                <br />
                <br />
                <p>6. PRIZES AND EARNINGS</p>
                Champz may organize contests, tournaments, or promotional events from time to time, where players can compete for prizes and
                winnings. The nature, rules, and eligibility criteria of such events will be clearly communicated within the game or through
                other official channels. The prizes and winnings offered may vary and are subject to change at the discretion of Champz.
                RiddleDrops reserves the right to modify, substitute, or cancel prizes without prior notice. Any taxes, fees, or charges
                associated with the receipt of prize or earnings shall be the sole responsibility of the respective winners. RiddleDrops
                does not guarantee the availability or performance of any external platforms, marketplaces, or exchanges where players may
                trade or exchange their earned rewards. Any transactions conducted through such platforms are subject to their own terms and
                conditions.
                <br />
                <br />
                <p>7. USER-GENERATED CONTENT</p>
                RiddleDrops may allow players to create or submit user-generated content (UGC) within the game. By submitting UGC, you grant
                RiddleDrops a worldwide, royalty-free, non-exclusive, transferable license to use, reproduce, distribute, display, and
                modify the UGC for the purposes of operating and promoting the game. You are solely responsible for the UGC you create or
                submit, and you represent and warrant that you have the necessary rights and permissions to do so. RiddleDrops is not
                responsible for any UGC posted by players and does not endorse or guarantee the accuracy, quality, or legality of such
                content.
                <br />
                <br />
                <p>8. ELIGIBILITY</p>
                To access and use Champz and to participate in any contests, tournaments or promotional events, you must be at least 13
                years old or have obtained consent from a parent or legal guardian. You are solely responsible for ensuring compliance with
                any applicable laws regarding access and use of the game from your jurisdiction.
                <br />
                <br />
                <p>9. OWNERSHIP AND LICENSE</p>
                All content, materials, and intellectual property within Champz, including but not limited to graphics, artwork, text,
                software, and gameplay mechanics, are the exclusive property of RiddleDrops or its licensors. You are granted a limited,
                non-exclusive, non-transferable license to use the game for personal, non-commercial purposes.
                <br />
                <br />
                <p>10. USER CONDUCT</p>
                While using Champz you agree to abide by the following rules:{" "}
                <ul>
                  <li>a. Do not engage in any activity that violates applicable laws, regulations, or the rights of others.</li>
                  <li>b. Do not engage in any form of cheating, hacking, or exploiting game vulnerabilities.</li>
                  <li>c. Do not harass, threaten, or harm other players or engage in any form of offensive or disruptive behavior.</li>
                  <li>d. Do not engage in any unauthorized commercial activities within or related to the game.</li>
                  <li>e. Do not share your account credentials or access the game using unauthorized methods.</li>
                </ul>
                <p>Prohibition of Multi-Accounting</p>
                To maintain a fair and balanced gaming environment, RiddleDrops strictly prohibits the use of multiple accounts by a single
                player (multi-accounting). Each player is allowed to create and use only one account to participate in Champz. Creating
                multiple accounts for the purpose of gaining unfair advantages, manipulating the game's economy, or any other reason is
                strictly forbidden. Champz employs various monitoring and detection mechanisms to identify and prevent multi-accounting.
                Players found in violation of this policy may face penalties, including but not limited to suspension or permanent ban of
                all associated accounts. Players are encouraged to report any suspected instances of multi-accounting to the Champz support
                team to help maintain a fair and enjoyable gaming experience for all participants. RiddleDrops reserves the right to
                suspend, terminate, or modify the access to the game for any player who violates these rules.
                <br />
                <br />
                <p>11. DISCLAIMERS AND LIMITATION OF LIABILITY</p>
                <div>
                  a.) Champz is provided on an "as is" and "as available" basis. We make no warranties or representations, express or
                  implied, regarding the game's availability, performance, or suitability for any purpose.{" "}
                </div>
                <div>
                  b.) RiddleDrops shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out
                  of or related to the use or inability to use the game.{" "}
                </div>
                <div>
                  c.) RiddleDrops does not guarantee the security, accuracy, or reliability of any information transmitted within the game
                  or related to the native $CHAMPZ token or Champz NFTs.
                </div>
                <div>
                  {" "}
                  d.) It is important to note that RiddleDrops as the issuer of NFTs does not exert direct influence over the market price
                  of these tokens.
                </div>
                <div>
                  {" "}
                  e.) The value and pricing of NFTs, including those minted within Champz, are subject to market dynamics, player demand,
                  and the broader ecosystem of NFT trading on supported marketplaces.{" "}
                </div>
                <div>
                  {" "}
                  f.) RiddleDrops acknowledges that market prices are determined by the community, external market forces, and the
                  decentralized nature of blockchain networks.{" "}
                </div>
                <br />
                <br />
                <p> 12. TERMINATION</p>
                <div>
                  RiddleDrops reserves the right to suspend, terminate, or modify your access to the game at any time and for any reason,
                  without prior notice. You may also terminate your use of the game at any time by ceasing to access or use it.{" "}
                </div>
                <br />
                <div>
                  12.1. Notifications Due to your anonymity, you accept and agree that RiddleDrops is not able to get in direct contact with
                  you for important information or announcement - Especially announcing the start and end of raffles, contests or
                  competitions. RiddleDrops will use Social Media platforms such as X and Telegram for these announcements. If any of these
                  services will resign, RiddleDrops will announce an adequate alternative.
                </div>
                <br />
                <div>
                  12.2. Governing Law and Jurisdiction These Terms of Use shall be governed by Austrian Law and in exclusion of UN-Sales
                  law. Place of jurisdiction for any disputes from the legal transaction under these terms and conditions is the locally and
                  factually responsible court of Graz, Austria.{" "}
                </div>
                <br />
                <br />
                <p>13. PRIVACY POLICY</p>
                <div>
                  13.1. Commitment RiddleDrops as controller of the processing of personal data within the scope of application of the
                  General Data Protection Regulation (hereinafter the GDPR) takes the protection of your personal data as its highest
                  priority. With regard to the processing of personal data, RiddleDrops adheres to all provisions of the GDPR, the Austrian
                  Data Protection Act, as well as other relevant national and EU provisions, and is committed to achieving the best possible
                  transparency. We process personal data in accordance with the principles of lawfulness, fairness, transparency, accuracy,
                  purpose limitation, data minimization, storage limitation, and integrity and confidentiality. Please read the Privacy
                  Policy carefully. In case of any unclarities or questions, please do not hesitate to contact us.
                </div>
                <br />
                <div>13.2. Controller</div> RiddleDrops GmbH <br /> Krenngasse 12
                <br /> 8010 Graz <br />
                https://www.champz.world/
                <br /> office@riddledrops.io
                <br /> <br />
                <div> 13.3. Purpose and lawfulness of data processing</div>
                <div>
                  13.3.1. Personal data Depending on the specific purpose, the following categories of personal data are processed: Player
                  name, blockchain wallet address, IP address, browser type, browser version, country, date and time of server request{" "}
                </div>{" "}
                <br />
                <div>
                  13.4. Visitors of our game website If you visit our game website at https://play.champz.world, we may process your
                  personal data:
                  <ul>
                    <li>a) Processing of personal data The data listed under point 13.3.1</li>
                    <li>
                      b) Purpose of data processing We process your personal data to ensure a stable website usage, to detect, analyze and
                      solve problems and to prevent attacks on our website. This enables us to operate our website in a proper, stable and
                      secure manner and to improve and further develop our website.
                    </li>
                    <li>
                      b) Purpose of data processing We process your personal data to ensure a stable website usage, to detect, analyze and
                      solve problems and to prevent attacks on our website. This enables us to operate our website in a proper, stable and
                      secure manner and to improve and further develop our website.
                    </li>
                    <li>
                      c) Legal basis of the data processing The data processing is based on our legitimate interest according to Art 6(1)(f)
                      GDPR. We have a legitimate interest in ensuring that our website can be operated properly and securely. On this way,
                      we want to protect our website visitors on the one hand and transport our website content to our website visitors
                      accordingly on the other hand. This data is not merged with other data sources.
                    </li>
                  </ul>
                </div>
                <div>
                  13.5. Contact If you contact us by e-mail at office@riddledrops.io or in any other way, we process your personal data as
                  follows:
                  <ul>
                    <li>a) Processing of personal data Contact data (point 13.3.1) and the personal data given to us otherwise.</li>
                    <li>
                      b) Purpose of data processing If you contact us, the personal data you provide us with will be processed by us for the
                      purpose of responding to your inquiry and in the event of follow-up questions.
                    </li>
                    <li>
                      c) Legal basis of the data processing The lawfulness of the data processing is based on Art 6(1)(b) GDPR. This means
                      that the processing of personal data based on your request is necessary for the implementation of pre-contractual
                      measures or for the performance of the contract. In other cases, we process your personal data in accordance with Art
                      6(1)(f) GDPR, which means that the processing of personal data is based on our legitimate interest of responding to
                      your inquiry. If you do not want us to process your personal data, we will not be able to respond to your inquiry.
                    </li>
                  </ul>
                </div>
                <div>13.6. Champignons of Arborethia</div>
                <div>
                  We process your personal data, if you are our contractual partner and/or player of the game "Champignons of Arborethia",
                  as follows:
                </div>
                <div>13.6.1. Data required for registration</div>
                <div>
                  <div>
                    a) Processing of personal data Personal data: Passport account address, if provided also personal name, player name,
                    e-mail address.
                  </div>
                  <div>
                    b) Purpose of data processing The personal data listed in this pricacy statement is processed in order to enter into a
                    contract with you so that you are able to play the game "Champignons of Arborethia". Without the processing of this
                    data, unfortunately, a registration and safe game play is not possible.
                  </div>
                  <div>
                    c) Legal basis of the data processing The lawfulness of the personal data processing is based on Art 6(1)(b) GDPR.
                    Without this personal data, it is not possible for us to enter into a contract with the players/contractual partners.
                  </div>
                </div>{" "}
                <div>13.6.2. Data for statistical purposes</div>
                <div>
                  <div>
                    a) Processing of personal data IP address, operating system, entry and exit page, browser type, browser version,
                    country, date and time of server request, etc.
                  </div>
                  <div>
                    b) Purpose of data processing For statistical purposes, we store your IP address for the maximum duration of one day.
                    This applies only to players of the game “Champignons of Arborethia” and not to visitors of our website.
                  </div>
                  <div>
                    c) Legal basis of the data processing The lawfulness of the personal data processing is based on Art 6(1)(a) GDPR. In
                    order to provide a better gaming experience and learn more about the number of players logged in daily, we therefore
                    store the IP address of the players for the duration of one day. We therefore obtain the consent of the players before
                    the registration. The stored IP addresses will be deleted by us after the expiration of one day.
                  </div>
                </div>{" "}
                <div>13.7. Fan page</div>
                <div>
                  We manage a 'fan page' across multiple social media platforms. Clicking on the provided link will take you directly to our
                  dedicated page on the respective social media platform.
                </div>
                <div>
                  <div>
                    a) Processing of personal data We process all messages, likes, photos, and content you submit, share, or otherwise
                    contribute to our social media pages, as well as their log files.
                  </div>
                  <div>
                    b) Purpose of data processing The purpose of this processing is to increase our web presence on various social media
                    channels and to provide you with information related to the game "Champignons of Arborethia". In addition, we would like
                    to learn more about the opinions of our players and their interactions with our products in order to better respond to
                    needs and provide optimal player support, as well as to provide you with the opportunity to interact with other people
                    about the game. In this context, we may process your personal data that you have posted on social media in the
                    interaction with us.
                  </div>
                  <div>
                    c) Legal basis of the data processing The lawfulness of the data processing is based on our legitimate interest in the
                    data processing (Art (6)(1)(f) GDPR) to increase our web presence and to transport information to potential players.
                  </div>
                </div>{" "}
                <div>13.8. Consent pursuant to Art 6(1)(a)</div>
                <div>
                  GDPR If the processing of personal data exceeds contractual or legal obligations, RiddleDrops will obtain the consent of
                  the person whose data is being processed. In the case of consent, the data will only be processed for the stated purpose.
                  Consent may be withdrawn at any time with immediate effect. This can be done via the following e-mail address:
                  office@riddledrops.io.
                  <br /> More information regarding consenting to our cookies policy can be found under Point 6.2.
                </div>
                <br />
                <br />
                <p>14. STORAGE PERIOD</p>
                <div>
                  Except for the storage period stated for statistical purposes the personal data is stored for the period of the
                  contractual relationship, and beyond that only in accordance with legal retention periods or other time limits within
                  which claims can be asserted.
                </div>
                <br />
                <br />
                <p>15. WEBSITE</p>
                <div>
                  15.1. Use of Information When visiting our website, the following personal data will be processed:
                  <ul>
                    <li>• date and time of the visit to our website,</li>
                    <li>• your IP address,</li>
                    <li>• name and version of your web browser</li>
                  </ul>
                </div>
                <div>
                  15.2. Legal base and purpose of data processing Personal data collected in regards to cookies is processed based on your
                  consent. Other personal data collected on our website are processed on the basis of our legitimate interest pursuant to
                  Art 6(1)(f) GDPR. These legitimate interests encompass:
                </div>
                <ul>
                  <li>• provision, development, and improvement of this website;</li>
                  <li>• compiling of reports on website activity;</li>
                  <li>• identification, prevention, and tracking of attacks on the website.</li>
                </ul>{" "}
                <br />
                <br />
                <p>16. CONTACT DATA</p>
                <div>
                  When you contact us via e-mail, we use the provided personal data (e-mail address and possibly your name and/or telephone
                  number) for the purpose of processing your request. We also store this data for possible subsequent requests. We do not
                  pass this data on to third parties without your consent. As soon as the storage of this data is no longer necessary and
                  there is no legal obligation to retain this data, we will delete this data. The lawfulness of the data processing is
                  pursuant to Art 6(1)(b) GDPR. This means that the processing of the data is necessary to carry out pre-contractual oder
                  contractual measures, which are carried out at the request of the person concerned.
                </div>
                <br />
                <br />
                <p>17. DATA SECURITY</p>
                <div>
                  The security of data is our highest priority. RiddleDrops has taken all necessary technical and organizational measures to
                  ensure the security of data processing and to process personal data in a way that ensures protection against unauthorized
                  access by third parties. RiddleDrop's IT infrastructure complies with common security requirements and is regularly
                  reviewed.{" "}
                </div>
                <div>
                  Our website uses the industry standard SSL (Secure Sockets Layer) for encryption. This ensures the confidentiality of your
                  personal data over the Internet. You can tell whether encrypted transmission is being used by the closed key/lock symbol
                  in your browser's display.
                </div>
                <br />
                <br />
                <p>18. CONFIDENTIALITY</p>{" "}
                <div>
                  All employees of RiddleDrops are bound by secrecy regarding the information entrusted or disclosed to them in the course
                  of their work; applicable even after termination of employment.
                </div>
                <br />
                <br />
                <p>19. RIGHTS OF DATA SUBJECT(S) / CONTACT</p>
                <div>
                  Our privacy police provides the following rights for individuals:
                  <ul>
                    <li>The right to be informed and of access under Art 15 GDPR</li>
                    <li>The right to rectification under Art 16 GDPR</li>
                    <li>The right to erasure (“right to be forgotten”) under Art 17 GDPR</li>
                    <li>The right to restriction of processing under 18 GDPR</li>
                    <li>The right to data portability under Art 20 GDPR and</li>
                    <li>The right to object under Art 21 GDPR</li>
                  </ul>
                </div>
                <div>
                  {" "}
                  If your personal data is processed based on your consent, you have the right to withdraw this consent with immediate
                  effect at any time. The legality of the processing of your personal data up to the point of withdrawl is not affected by
                  the withdrawal of consent. The withdrawal of consent to the processing of your personal data in the context of the storage
                  of cookies can be done in particular by deleting the cookies. In the event your personal data is processed on the basis of
                  Art 6(1)(f) GDPR, you have the right to object to the data processing at any time, provided that there are grounds for
                  this that arise from your particular situation. In such a case we no longer process the personal data unless we are able
                  to demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the
                  data subject or for the establishment, exercise or defense of legal claims. If you have any questions regarding the
                  processing of your personal data, want to object to the processing of your data or to revoke consent or if you feel your
                  data protection rights have been violated, do not hesitate to contact us:{" "}
                </div>
                <br />
                RiddleDrops GmbH <br /> Krenngasse 12 <br /> 8010 Graz <br /> https://www.champz.world/ <br />
                office@riddledrops.io <br />
                <br /> Data subjects also have a right to appeal to the supervisory authority: Austrian Data Protection Authority,
                Barrichgasse 40-42, 1030 Wien, e-mail: dsb@dsb.gv.at.
              </div>
            </div>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Terms;
