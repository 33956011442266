import React from "react";
import { Container, Row } from "react-bootstrap";

import playToken from "assets/img/main/play/main-play-token.svg";
import playScheme from "assets/img/main/play/main-play_scheme_usdc.png";

const P2E = ({ isMdScreen }) => {
  return (
    <section id="main-play" className="main-play-section">
      <Container>
        <Row>
          <div className="text-wrap" data-aos="fade-up" data-aos-delay="300">
            <h2>
              Play and Earn
              <br />
              <span className="icon">
                <img src={playToken} alt="" />
              </span>
            </h2>
            <p>Unique play and earn mechanics.</p>
          </div>
          <div className="cta-wrap">
            <a href="https://docs.champz.world/currencies/earn-truffles" target="_blank" className="btn-main">
              Learn more
            </a>
          </div>

          {/* <div className="scheme-wrap" data-aos="fade-up" data-aos-delay="300">
            <img src={playScheme} alt="Scheme" />
          </div> */}
        </Row>
      </Container>
    </section>
  );
};

export default P2E;
