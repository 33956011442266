import React from "react";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

import Stats_fights from "./stats_fights";
import Stats_eth from "./stats_eth";

import learnImg from "assets/img/main/learn/main-learn-img.png";
import learnMobileImg from "assets/img/main/learn/main-learn-mobile-img.png";
import Stats_dau from "./stats_dau";
import Stats_chars from "./stats_chars";
import Stats_day from "./stats_day";
import Stats_rev_share_eth from "./stats_rev_share_eth";

const Statistics = ({ isSmScreen }) => {
  return (
    <section id="statistics" className="main-learn-section">
      <Container>
        <Row>
          <div className="headline-wrap" data-aos="fade-up" data-aos-delay="300">
            <h2>Game Statistics</h2>
          </div>
          <div className="items-wrap" data-aos="fade-up" data-aos-delay="600">
            <div className="item">
              <h3>
                <Stats_day />
              </h3>
              <p>Days live</p>
            </div>
            <div className="item">
              <h3>
                <Stats_dau />
              </h3>
              <p>
                Daily Active Users <br /> last 7 days average
              </p>
            </div>
            {/* <div className="item">
              <h3>
                <Stats_fights />
              </h3>
              <p>Total number of fights</p>
            </div> */}
            <div className="item">
              <h3>
                <Stats_eth />
              </h3>
              <p>Amount earned by our players</p>
            </div>
            {/* <div className="item">
              <h3>SOLD OUT</h3>
              <p>
                <Link
                  to={{
                    pathname: "https://opensea.io/collection/champz-official",
                  }}
                  target="_blank"
                  className="nav-link"
                >
                  First Gen sold out. Get your Champz NFTs on OpenSea
                </Link>
              </p>
            </div> */}
          </div>

          <div className="learn-wrap" data-aos="fade-up" data-aos-delay="600">
            <Link to="/lore">
              <img src={isSmScreen ? learnMobileImg : learnImg} alt="Learn" />
            </Link>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Statistics;
