export function showAsUsd(value) {
  const formattedPrice = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);

  return formattedPrice;
}
