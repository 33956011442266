import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import statsTokenOne from "assets/img/main/stats/main-stats-token-1.svg";
import statsTokenTwo from "assets/img/main/stats/main-stats-token-2.svg";
import Stats_burned from "./stats_burned";

const Tokenomics = ({ isMdScreen }) => {
  return (
    <section id="tokenomics" className="main-stats-section">
      <Container>
        <Row>
          <div className="headline-wrap" data-aos="fade-up" data-aos-delay="300">
            <h2>$CHAMPZ Tokenomics</h2>
          </div>
          <div className="items-wrap" data-aos="fade-up" data-aos-delay="300">
            <div className="item">
              <h3>1 Billion</h3>
              <p>Total Supply</p>
            </div>
            <div className="item">
              <h3>0%</h3>
              <p>Tax On Buy</p>
            </div>
            <div className="item">
              <h3>5%</h3>
              <p>Tax On Sell</p>
            </div>
            {/* <div className="item">
              <h3>1:1</h3>
              <p>Swap from old Champz token</p>
            </div> */}
          </div>
          <div className="tokens-wrap">
            <img src={statsTokenOne} className="token token-1" alt="Token" data-aos="fade-up" data-aos-delay="300" />
            <img src={statsTokenTwo} className="token token-2" alt="Token" data-aos="fade-up" data-aos-delay="300" />
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Tokenomics;
