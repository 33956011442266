import React from "react";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

import img_W3GG from "assets/img/main/partners/W3GG.png";
import img_SELF from "assets/img/main/partners/Self.png";
import img_Reaktor from "assets/img/main/partners/Reaktor.png";
import img_GGPlay from "assets/img/main/partners/GGPlay.png";
import img_Forge from "assets/img/main/partners/Forge.png";
import img_EarnAlliance from "assets/img/main/partners/EarnAlliance.png";
import img_BitRivals from "assets/img/main/partners/BitRivals.png";
import img_IMG from "assets/img/main/partners/immutable.png";
import img_Altura from "assets/img/main/partners/Altura.png";

const partnersData = [
  {
    id: 0,
    img: img_IMG,
    name: "Immutable",
    link: "https://www.immutable.com/products/immutable-zkevm",
  },
  {
    id: 1,
    img: img_Altura,
    name: "Altura",
    link: "https://www.alturanft.com/",
  },
  {
    id: 2,
    img: img_W3GG,
    name: "W3GG",
    link: "https://w3gg.io/",
  },
  {
    id: 3,
    img: img_SELF,
    name: "SELF",
    link: "https://selfcrypto.io/",
  },
  {
    id: 4,
    img: img_Reaktor,
    name: "Reaktor",
    link: "https://unhumanly.com/",
  },
  {
    id: 5,
    img: img_GGPlay,
    name: "GG Play",
    link: "https://ggplay.id/",
  },
  {
    id: 6,
    img: img_Forge,
    name: "Forge",
    link: "https://forge.gg/",
  },
  {
    id: 7,
    img: img_EarnAlliance,
    name: "Earn Alliance",
    link: "https://www.earnalliance.com/",
  },
  {
    id: 8,
    img: img_BitRivals,
    name: "Bit Rivals",
    link: "https://token.bitrivals.app/",
  },
  // {
  //   id: 7,
  //   img: img_Arbitrum,
  //   name: "Arbitrum",
  //   link: "https://arbitrum.foundation/",
  // },
];

const Partners = ({ isSmScreen }) => {
  return (
    <section id="partners" className="main-partners-section">
      <Container>
        <Row>
          <div className="headline-wrap" data-aos="fade-up" data-aos-delay="300">
            <h2>Partners</h2>
          </div>

          <div className="partners-wrap" data-aos="fade-up" data-aos-delay="600">
            {partnersData &&
              partnersData.map(({ id, img, name, link }) => (
                <div className="partner-item" key={id}>
                  <Link to={{ pathname: link }} target="_blank" className="partner-link">
                    <img className="partner-img" src={img} alt={"Logo " + name} title={name} />
                  </Link>
                </div>
              ))}
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Partners;
